import supportedLanguages from './supported-languages';
import defaultGlobalClientConfig, {
  IGlobalClientConfig,
} from '../../../default/config/GlobalClientConfig/GlobalClientConfig';
import {
  AuthenticationFlowTypes,
  FeaturedEventsCarouselType,
} from '../../../../config/Enums/App';
import Routes from '../../../../config/Routes/Routes';
import { MsisdnRequiredness } from '../../../../config/Enums/MsisdnRequiredness';

const GlobalClientConfig: IGlobalClientConfig = {
  ...defaultGlobalClientConfig,
  siteTitle: 'Arena Esports',
  clientName: 'Arena Esports',
  partnerCopyrightName: 'Etisalat',
  copyrightName: 'Swarmio Media',
  clientOrganizationSiteUrl: 'https://swarmio.media/',
  authenticationFlowType: AuthenticationFlowTypes.Carousel,
  featuredEventsCarouselType: FeaturedEventsCarouselType.Centered,
  numberOfEventsToShowOnHomePage: 6,
  numberOfServersToShowOnHomePage: 2,
  accountSetupRoute:
    process.env.VITE_APP_SHOW_SUBSCRIPTIONS &&
    process.env.VITE_APP_SHOW_SUBSCRIPTIONS.toLowerCase() === 'true'
      ? Routes.subscribe
      : Routes.connectGameAccounts,
  nav: {
    orientation: 'vertical',
  },
  currency: {
    showOnlyPoints: true,
  },
  internationalization: {
    defaultLng: 'en',
    supportedLngs: supportedLanguages,
  },
  eventDiscordLink: 'https://discord.gg/wVf9XXs55M',
  msisdn: {
    format: 'AE',
    requiredness: MsisdnRequiredness.ABSENT,
  },
  countryCode: 784,
  storeBanner: (storePartnertId: string | undefined) =>
    `https://store-assets.arenaesports.ae/store-banners/headers/${storePartnertId}/header-image.png`,
};

export default GlobalClientConfig;
